import dayjs from 'dayjs';
import { defineStore } from 'pinia';

interface State {
  exploreDateRange: {
    start: Date | null;
    end: Date | null;
  };
  aggregationLevel: string;
  groupChild: string;
  meter: number | null;
}

export const appStore = defineStore({
  id: 'appStore',
  state: (): State => ({
    exploreDateRange: {
      start: dayjs().subtract(1, 'month').toDate(),
      end: dayjs().toDate(),
    },
    aggregationLevel: 'day',
    groupChild: 'meter',
    meter: null,
  }),
  getters: {
    getExploreDateRange(): State['exploreDateRange'] {
      return this.exploreDateRange;
    },
    getMeter(): State['meter'] {
      return this.meter;
    },
    getAggregationLevel(): State['aggregationLevel'] {
      return this.aggregationLevel;
    },
    getGroupChild(): State['groupChild'] {
      return this.groupChild;
    },
  },
  actions: {
    setExploreDateRange(dateRange: State['exploreDateRange']): void {
      this.exploreDateRange = dateRange;
    },
    setMeter(meter: State['meter']): void {
      this.meter = meter;
    },
    setAggregationLevel(aggregationLevel: State['aggregationLevel']): void {
      this.aggregationLevel = aggregationLevel;
    },
    setGroupChild(groupChild: State['groupChild']): void {
      this.groupChild = groupChild;
    },
  },
});
